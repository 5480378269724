import React, {useEffect, useState, useRef} from 'react'
import HTMLFlipBook from "react-pageflip"
import img01 from '../img/01.jpg'
import img02 from '../img/02.jpg'
import img03 from '../img/03.jpg'
import img04 from '../img/04.jpg'
import img05 from '../img/05.jpg'
import img06 from '../img/06.jpg'
import img07 from '../img/07.jpg'
import img08 from '../img/08.jpg'
import img09 from '../img/09.jpg'
import img10 from '../img/10.jpg'
import img11 from '../img/11.jpg'
import img12 from '../img/12.jpg'
import img13 from '../img/13.jpg'
import img14 from '../img/14.jpg'
import img15 from '../img/15.jpg'
import img16 from '../img/16.jpg'
import img17 from '../img/17.jpg'
import img18 from '../img/18.jpg'
import img19 from '../img/19.jpg'
import img20 from '../img/20.jpg'
import img21 from '../img/21.jpg'
import img22 from '../img/22.jpg'
import img23 from '../img/23.jpg'
// import img24 from '../img/24.png'
// import img25 from '../img/25.png'
// import img26 from '../img/26.png'
// import img27 from '../img/27.png'
import { FaCircleChevronRight, FaCircleChevronLeft} from "react-icons/fa6";
  
export default function Book({objectProp}) {
    const image = [img01, img02, img03, img04, img05, img06, img07, img08, img09, img10,
        img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23]
    
    const flipBookRef = useRef(null)

    const nextButtonClick = () => {
        if (flipBookRef.current) {
            flipBookRef.current.pageFlip().flipNext();
        }
    }
    
    const prevButtonClick = () => {
        if (flipBookRef.current) {
            flipBookRef.current.pageFlip().flipPrev();
        }
    }

  return (
    <div className="overflow-hidden bg-gradient-to-r from-cyan-500 to-blue-500 w-screen h-screen flex items-center">
        <div className={objectProp.isMobile?
            "m-auto w-auto h-auto relative"
            :objectProp.isTablet?"m-auto w-[80%] h-auto relative"
            :objectProp.isTabletBig?"m-auto w-[80%] h-auto relative"
            :objectProp.isPCBig?"m-auto w-[60%] h-auto relative"
            :"m-auto w-[55%] h-auto relative flex items-center"
            }>
        <FaCircleChevronLeft onClick={prevButtonClick} size={objectProp.isPCBig?"65px":"50px"} color='#fff' className={objectProp.isMobile?
            "mr-[100px] absolute bottom-[-80px] left-[100px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
            :objectProp.isTablet? "mr-[100px] absolute bottom-[-90px] left-[40px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
            :objectProp.isPCBig? "mr-[100px] absolute top-[50%] left-[-80px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
            :"mr-[100px] absolute top-[50%] left-[-60px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
            } />

            <HTMLFlipBook size={objectProp.isMobile?"fixed":"stretch"} width={320} height={415} className="m-auto cursor-pointer shadow-[]" ref={flipBookRef}>
                {
                    image.map((img, index)=>{
                        return <div key={index} className="demoPage border-[4px] border-[#fff] rounded-[8px]">
                                <img src={img} className="w-full h-full rounded-[3px]" />
                            </div>
                    })
                }
            </HTMLFlipBook>

            <FaCircleChevronRight onClick={nextButtonClick} size={objectProp.isPCBig?"65px":"50px"} color='#fff' className={objectProp.isMobile?
                "mr-[100px] absolute bottom-[-80px] right-[10px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
                :objectProp.isTablet? "mr-[100px] absolute bottom-[-90px] right-[-60px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
                :objectProp.isPCBig?"mr-[100px] absolute top-[50%] right-[-180px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
                :"mr-[100px] absolute top-[50%] right-[-160px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
                } />
        </div>
    </div>
  )
}
