import React, {useState} from 'react'
import { IoMenu } from "react-icons/io5";
import Logo from '../img/Logovhu.png'
import { click } from '@testing-library/user-event/dist/click';

export default function Header({objectProp}) {
    const [click, setClick] = useState(false)

  return (
    <>
        {   objectProp.isMobile?
            <>
                <div className={ click?
                    "bg-white w-full h-[60px] absolute top-0 left-0 flex justify-between"
                    :"w-full h-[60px] absolute top-0 left-0 flex justify-between"
                }>
                    <div className="pt-[10px]">
                        <img src={Logo} className="w-[250px] m-auto ml-[10px]" />
                    </div>
                    <IoMenu onClick={()=>setClick(!click)} size={"35px"} color={click?"#222":"#fff"} className={ click?
                        "mt-[10px] border-[1px] border-[#777] rounded-[4px] mr-[10px] cursor-pointer"
                        :"mt-[10px] border-[1px] border-[#fff] rounded-[4px] mr-[10px] cursor-pointer"
                    }/>
                </div>
                <div className={!click?"hidden":"w-full h-[120px] bg-white absolute top-[59px] left-0 z-[100] shadow-xl"}>
                    <ul className="ml-[20px]">
                        <a href='https://vhu.edu.vn/' target='_blank'>
                            <li className="py-[10px] text-[#001666] font-bold">TRANG CHỦ</li>
                        </a>
                        <a href='https://ts.vhu.edu.vn/' target='_blank'>
                            <li className="pb-[10px] text-[#001666] font-bold">THÔNG TIN TUYỂN SINH</li>
                        </a>
                        <a href='https://dangky.vhu.edu.vn/' target='_blank'>
                            <li className="pb-[10px] text-[#001666] font-bold">ĐĂNG KÝ XÉT TUYỂN</li>
                        </a>
                    </ul>
                </div>
            </>
            :
            <div className="absolute top-0 left-[50%] translate-x-[-50%] max-w-[1280px] w-[100%] h-[60px] flex justify-between pt-[10px]">
                <div className="w-[600px] text-center">
                    <img src={Logo} className={objectProp.isMobile?
                    "ml-[10px]"
                    :objectProp.isPCBig?"w-[400px] m-auto"
                    :"w-[300px] m-auto"} />
                </div>
                <div className="w-[680px] text-center pt-[10px] flex flex-wrap">
                    <a href='https://vhu.edu.vn/' target="_blank" className={ objectProp.isMobile?
                        "text-white px-[15px] font-bold pb-[5px] pl-[50px] text-[14px]"
                        :objectProp.isPCBig? "text-white px-[15px] font-bold text-[14px]"
                        :"text-white px-[15px] font-bold text-[14px]"
                    }>TRANG CHỦ</a>
                    
                    <a href='https://ts.vhu.edu.vn/' target="_blank" className={ objectProp.isMobile?
                        "text-white px-[15px] font-bold pb-[10px] pl-[50px] text-[14px]"
                        :objectProp.isPCBig? "text-white px-[15px] font-bold text-[14px]"
                        :"text-white px-[15px] font-bold text-[14px]"
                    }>THÔNG TIN TUYỂN SINH</a>

                    <a href='https://dangky.vhu.edu.vn/' target="_blank" className={ objectProp.isMobile?
                        "text-white px-[15px] font-bold pb-[10px] pl-[50px] text-[14px]"
                        :objectProp.isPCBig? "text-white px-[15px] font-bold text-[14px]"
                        :"text-white px-[15px] font-bold text-[14px]"
                    }>ĐĂNG KÝ XÉT TUYỂN</a>
                </div>
            </div> 
        }
    </>
  )
}
