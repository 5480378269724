import React, {useEffect, useState} from 'react'
import {BrowserRouter as Router} from 'react-router-dom';
import Book from '../src/componennt/Book'
import Footer from './componennt/Footer'
import Header from './componennt/Header'
import Page from './componennt/Router';

function App() {
    // độ dài , rộng màn hình
    const [windownSize, setWindownSize] = useState({width: undefined, height: undefined,})
    const [isMobile, setIsmobile] = useState(false)
    const [isTablet, setTablet] = useState(false)
    const [isTabletBig, setTabletBig] = useState(false)
    const [isPCBig, setPCBig] = useState(false)

    useEffect(()=>{
        const handleSize = () =>{
            setWindownSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleSize)
        handleSize()
        return () => { window.removeEventListener('resize', handleSize)}
    },[])

    useEffect(()=>{
        windownSize.width <= 500 ? setIsmobile(true) : setIsmobile(false)
        windownSize.width >= 501 && windownSize.width < 800 ? setTablet(true) : setTablet(false)
        windownSize.width >= 800 && windownSize.width <= 1024 ? setTabletBig(true) : setTabletBig(false)
        windownSize.width >= 1281 ? setPCBig(true) : setPCBig(false)
    },[windownSize])

    const objectProp = {
        isMobile: isMobile,
        isTablet: isTablet,
        isTabletBig: isTabletBig,
        isPCBig: isPCBig
    }
  return (
    <Router>
        <div className="px-auto">
            <Header objectProp={objectProp}/>
            <Page objectProp={objectProp}/>
            <Footer objectProp={objectProp}/>
        </div>
    </Router>
  );
}

export default App;
