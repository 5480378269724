import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Book from './Book';
import Demo from './Demo';

export default function Router({objectProp}) {
  return (
    <Routes>
        <Route path="/" element={<Book objectProp={objectProp}/>} />
        <Route path="/demo" element={<Demo objectProp={objectProp}/>} />

        <Route path="*" element={<></>} />
    </Routes>
  )
}
