import React, {useEffect, useState, useRef} from 'react'
import HTMLFlipBook from "react-pageflip"
import img01 from '../img/d01.png'
import img02 from '../img/d02.png'
import img03 from '../img/d03.png'
import img04 from '../img/d04.png'
import img05 from '../img/d05.png'
import img06 from '../img/d06.png'
import img07 from '../img/d07.png'
import img08 from '../img/d08.png'
import img09 from '../img/d09.png'
import img10 from '../img/d10.png'
import img11 from '../img/d11.png'
import img12 from '../img/d12.png'
import img13 from '../img/d13.png'
import img14 from '../img/d14.png'
import img15 from '../img/d15.png'
import img16 from '../img/d16.png'
import img17 from '../img/d17.png'
import img18 from '../img/d18.png'
import img19 from '../img/d19.png'
import img20 from '../img/d20.png'

import { FaCircleChevronRight, FaCircleChevronLeft} from "react-icons/fa6";

export default function Demo({objectProp}) {
    const image = [img01, img02, img03, img04, img05, img06, img07, img08, img09, img10,
        img11, img12, img13, img14, img15, img16, img17, img18, img19, img20]
    
    const flipBookRef = useRef(null)

    const nextButtonClick = () => {
        if (flipBookRef.current) {
            flipBookRef.current.pageFlip().flipNext();
        }
    }
    
    const prevButtonClick = () => {
        if (flipBookRef.current) {
            flipBookRef.current.pageFlip().flipPrev();
        }
    }

  return (
    <div className="overflow-hidden bg-gradient-to-r from-cyan-500 to-blue-500 w-screen h-screen flex items-center">
        <div className={objectProp.isMobile?
            "m-auto w-auto h-auto relative"
            :objectProp.isTablet?"m-auto w-[80%] h-auto relative"
            :objectProp.isTabletBig?"m-auto w-[80%] h-auto relative"
            :objectProp.isPCBig?"m-auto w-[80%] h-auto relative"
            :"m-auto w-[80%] h-auto relative flex items-center"
            }>
        <FaCircleChevronLeft onClick={prevButtonClick} size={objectProp.isPCBig?"65px":"50px"} color='#fff' className={objectProp.isMobile?
            "mr-[100px] absolute bottom-[-80px] left-[100px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
            :objectProp.isTablet? "mr-[100px] absolute bottom-[-90px] left-[40px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
            :objectProp.isPCBig? "mr-[100px] absolute top-[50%] left-[-80px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
            :"mr-[100px] absolute top-[50%] left-[-60px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
            } />

            <HTMLFlipBook size={objectProp.isMobile?"fixed":"stretch"} width={objectProp.isMobile?380:800} height={objectProp.isMobile?350:600} className="m-auto cursor-pointer shadow-[]" ref={flipBookRef}>
                {
                    image.map((img, index)=>{
                        return <div key={index} className="demoPage border-[4px] border-[#fff] rounded-[8px]">
                                <img src={img} className="w-full h-full rounded-[3px]" />
                            </div>
                    })
                }
            </HTMLFlipBook>

            <FaCircleChevronRight onClick={nextButtonClick} size={objectProp.isPCBig?"65px":"50px"} color='#fff' className={objectProp.isMobile?
                "mr-[100px] absolute bottom-[-80px] right-[10px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
                :objectProp.isTablet? "mr-[100px] absolute bottom-[-90px] right-[-60px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
                :objectProp.isPCBig?"mr-[100px] absolute top-[50%] right-[-180px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
                :"mr-[100px] absolute top-[50%] right-[-160px] translate-y-[-50%] cursor-pointer opacity-[0.8] hover:opacity-[1] z-[100]"
                } />
        </div>
    </div>
  )
}
