import React from 'react'

export default function Footer({objectProp}) {
  return (
    <div className={objectProp.isMobile?
    "bg-gradient-to-r from-cyan-500 to-blue-500 w-screen h-[30px] flex justify-center text-white mt-[-20px] text-[14px] font-bold"
    :objectProp.isPCBig?"bg-gradient-to-r from-cyan-500 to-blue-500 w-screen h-[30px] flex justify-center text-white mt-[-50px] text-[18px] font-bold"
    :"bg-gradient-to-r from-cyan-500 to-blue-500 w-screen h-[30px] flex justify-center text-white mt-[-30px] text-[13px] font-bold"
        }>
        Bản quyền ©2024 Trường Đại học Văn Hiến
    </div>
  )
}
